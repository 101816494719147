import axios from 'axios';
import isBrowser from '../util/gatsby';
import { navigate } from 'gatsby';
const baseURL =
  (process.env.GATSBY_VERCEL_ENV === 'production') |
  (process.env.GATSBY_PROD_API === 'TRUE') |
  (process.env.GATSBY_VERCEL_GIT_COMMIT_REF === 'old-master')
    ? 'https://mystoriesmatter.com/api'
    : 'https://public.cuebackqa.com/api';
const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use((req) => {
  if (isBrowser) {
    req.headers['X-CSRF-TOKEN'] = localStorage.getItem('idToken');
  }
  return req;
});

axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data.ResponseCode === 405) {
      // localStorage.clear();
      navigate('/');
    }
    return response.data;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.error('Error: ', error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
